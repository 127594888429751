<template>
  <div
    ref="scroll-save"
    class="gap-errors text-sm bg-gray-100 rounded-lg border-2 border-red-500 border-solid max-w-full flex flex-col items-stretch gap-1 overflow-hidden relative"
    :class="{'gap-errors--loading': isLoading}"
    role="alert"
  >
    <div class="p-4 flex items-center gap-4 justify-between">
      <div class="flex items-center gap-3">
        <svg
          aria-hidden="true"
          class="flex-shrink-0 inline w-5 h-5 text-red-600"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="text-red-600 font-medium">
          {{ message }}
        </span>
      </div>
      
      <button
        class="inline-flex flex-shrink-0 items-center justify-between w-32 px-2 py-1.5 px-3 bg-blue-600 text-white-text font-medium leading-tight rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-auto select-none"
        @click="expandToggle()" :class="{gap_error_btn: previewForm}"
      >
        <span class="mr-2">{{ expanded ? "Hide" : "Show" }} Gaps</span>
        <svg
          class="w-2.5 h-2.5 transform transition-all"
          :class="{ '-rotate-180': expanded }"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </button>
    </div>
    <transition name="collapse">
      <div v-show="expanded" >
        <div v-if="isLoading" class="gap_loader items-center justify-center p-4 flex w-full">
          <loader size="9" />
        </div>
        <div class="timeline w-full">
          <div ref="vis"></div>
        </div>
      </div>
    </transition>
  
  </div>
</template>

<script>
import { Timeline } from "vis-timeline/standalone";
import loader from "@shared/loader";
// import gapModal from "../components/gapModal.vue";

const gapValidGroups = {
  [false]: {
    id: 'invalid',
    itemClass: 'invalid',
    itemContent: 'Gap',
    className: 'invalid',
    content: 'Gaps',
  },
  [true]: {
    id: 'valid',
    itemClass: 'valid',
    itemContent: '',
    className: 'valid',
    content: '',
  },
}

export default {
  components: {
    loader,
    // gapModal,
    
  },
  props: {
    // gaps: {
    //   type: Object,
    //   required: true,
    // },
    gapGroup: {
      type: Object,
      default: () => {},
    },
    message: {
      type: String,
      default: 'We have identified following gaps in the data submitted by you. Please provide relevant information for below mentioned gaps.',
    },
    previewForm: {
      type: Boolean,
      default: false,
    },
    blockData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: true,
      expanded: true,
      timeline: null,
      show:false,
      change:false
    }
  },
  computed: {
    gaps() {
      if(this.gapGroup.timeline?.length) {
        return this.gapGroup.timeline
      } else {
        if(JSON.parse(localStorage.getItem('gapTimeline_'+this.blockData?.block_id))?.block_id == this.blockData?.block_id) {
          return JSON.parse(localStorage.getItem('gapTimeline_'+this.blockData?.block_id)).timeline
        } else {
          return this.gapGroup.timeline
        }
      }
    },
    // items() {
    //   const validGaps = this.gaps
    //     ?.filter(gap => !gap.invalid_gap)
    //     ?.filter(gap => gap.field?.value)
    //     ?.map(gap => {
    //       const gapValue = JSON.parse(gap.field?.value);
    //       const startDate = gapValue.from?.split(' ')[0]
    //       const endDate = gapValue.to?.split(' ')[0]
    //       return {
    //         start: new Date(startDate),
    //         end: new Date(endDate),
    //         invalid_gap: false,
    //       }
    //     })
    //     .map(this.getGapItem);
    //       console.log("In Provess",validGaps,"checking some",this.gaps.filter(gap => {
    //       console.log("IN Invalid",validGaps?.length)
    //       return !(validGaps
    //         .some(timeline =>
    //           new Date(timeline.start).getTime() <= new Date(gap.end).getTime()
    //           && new Date(timeline.end).getTime() >= new Date(gap.start).getTime())
    //         );
    //     }));
    //   const invalidGaps = this.gaps
    //     .filter(gap => gap.invalid_gap)
    //     .filter(gap => {
    //       console.log("IN Invalid",validGaps?.length)
    //       return !(validGaps
    //         .some(timeline =>
    //           new Date(timeline.start).getTime() <= new Date(gap.end).getTime()
    //           && new Date(timeline.end).getTime() >= new Date(gap.start).getTime())
    //         );
    //     })
    //     .map(gap => {
    //       return {
    //         start: gap.start,
    //         end: gap.end,
    //         invalid_gap: true,
    //       }
    //     })
    //     .map(this.getGapItem);
    //   console.log("valid", validGaps)
    //   console.log("invalid", invalidGaps)
    //   return [...validGaps, ...invalidGaps];
    // },
    items() {
      let validGaps = [];
      let gaps = JSON.parse(JSON.stringify(this.gaps)); 
      let restGap =  JSON.parse(JSON.stringify(this.gaps));
      // Process valid gaps
      for (let i = 0; i <  gaps.length; i++) {
        let gap = gaps[i];
        if (!gap.invalid_gap && gap.field && gap.field.value) {
          let gapValue = gap.field ? JSON.parse(gap.field.value) : null;
          let startDate = gapValue && gapValue.from ? gapValue.from.split(' ')[0] : null;
          let endDate = gapValue && gapValue.to ? gapValue.to.split(' ')[0] : null;
          let validGap = {
            start: new Date(startDate),
            end: new Date(endDate),
            invalid_gap: false,
          };
          validGaps.push(this.getGapItem(validGap));
        }
      }
      let invalidGaps = [];
      // Process invalid gaps
      for (let i = 0; i < restGap.length; i++) {
        let gap = restGap[i];
        if (gap.invalid_gap) {
          let isInvalid = true;
          for (let j = 0; j < validGaps.length; j++) {
            let timeline = validGaps[j];
            if (
              new Date(timeline.start).getTime() <= new Date(gap.end).getTime() &&
              new Date(timeline.end).getTime() >= new Date(gap.start).getTime()
            ) {
              isInvalid = false;
              break;
            }
          }
          if (isInvalid) {
            invalidGaps.push(this.getGapItem({
              start: gap.start,
              end: gap.end,
              invalid_gap: true,
            }));
          }
        }
      }
      return validGaps.concat(invalidGaps);
},

    groups() {
      return Object.values(gapValidGroups).filter(this.checkNotEmptyGroup);
    },
    vis_data() {
      return {
        items: this.items,
        groups: this.groups,
        vis_options: {
          // tooltip: {
          //   followMouse: true,
          //   overflowMethod: "cap",
          // },
          onInitialDrawComplete: this.reloadTimeline,
        },
      }
    },
  },
  watch: {
    items: {
      handler(newVal, oldVal) {
        if(this.gapStoreCompare(newVal,oldVal)){
        this.setVis();
      }
      else{
        this.isLoading = false;
      }
      },
    },
  },
  mounted() {
    this.setVis();
    // this.$emit('reload'); //Stoped every time scrolling of blocks which contains gap errors
  },
  methods: {
    gapStoreCompare(newVal,oldVal){
      return oldVal.some((item,index) => {
        return item.start.toString() !== newVal[index].start.toString() || item.end.toString() !== newVal[index].end.toString()
        }
        ) 
    },
    setVis() {
      this.isLoading = true;
      const container = this.$refs.vis;
      container.innerHTML = '';
      this.timeline = new Timeline(container, this.vis_data.items, this.vis_data.groups, this.vis_data.vis_options);
      this.timeline.on('click', this.selectGap);
    },
    checkGaps(gaps,timeline){ 
      for (let i = 0; i < gaps.length; i++) {
    const gap = gaps[i];
    const gapStartDate = new Date(gap.start).getTime();
    const gapEndDate = new Date(gap.end).getTime();
    const timelineStartDate = new Date(timeline.start).getTime();
    const timelineEndDate = new Date(timeline.end).getTime();

    if (timelineStartDate <= gapEndDate && timelineEndDate >= gapStartDate) {
      return true; // At least one valid gap found
    }
  }
  return false; // No valid gaps found
    },
    getGapItem(payload) {
      // const isValid = false; // for testing
      const isValid = !payload.invalid_gap;
      const group = gapValidGroups[isValid];
      const startDate = this.formateGapGraphDate(new Date(payload?.start));
      const endDate = this.formateGapGraphDate(new Date(payload?.end));
      return {
        gapData: payload,
        start: new Date(payload.start),
        end: new Date(payload.end),
        content: group.itemContent,
        group: group.id,
        className: `gap truncate cursor-pointer gap--${group.itemClass}`,
        title: `
          <div>
            <div>
              <b>From:</b>
              <span>${startDate}</span>
            </div>
            <div class="flex gap-1">
              <b>To:</b>
              <span>${endDate}</span>
            </div>
          </div>`,
      }
    },
    checkNotEmptyGroup(group) {
      return this.items.some(el => el.group === group.id);
    },
    selectGap(e) {
      this.showGap();
      const gap = this.vis_data.items.find(el => el.id === e.item || el.group == 'invalid');
      if (gap?.gapData.invalid_gap)
        this.$emit('select:gap', gap);
    },
    expandToggle() {
      this.expanded = !this.expanded;
    },
    reloadTimeline() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    showGap(){
      this.$emit("showGapModal",this.showGapModal);
    },
    formateGapGraphDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .gap {
    &--invalid {
      @apply bg-red-100 border-red-400;
      &.vis-selected {
        @apply border-primary bg-red-200;
      }
    }
  }
}
.collapse-enter-active {
  transition: all .3s linear;
}
.collapse-leave-active {
  transition: all .2s linear;
}
.collapse-enter, .collapse-leave-to {
  opacity: 0;
}
.gap-errors {
  .timeline {
    transition: opacity .3s;
  }
  &--loading {
    @apply h-40;
    .timeline {
      opacity: 0;
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 374px){
  .gap_error_btn{
    position:relative;
    width: 55%;
    bottom: 50px !important;
    right:16% !important;
    font-size:12px;
  }
}

@media screen and (min-width: 375px) and (max-width: 389px){
  .gap_error_btn{
    position:relative;
    width: 55%;
    bottom: 50px !important;
    right:16% !important;
    font-size:12px;
  }
}

@media screen and (min-width: 390px) and (max-width: 414px){
  .gap_error_btn{
    position:relative;
    bottom: 50px !important;
    right:10% !important;
  }
}

</style>